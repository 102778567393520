import React, { useState } from "react";
import axiosInstance from "./../../../../../api/axiosInstance";
import { FaVideo, FaTrash } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";

const VideoInspection = () => {
  const [videos, setVideos] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const { unitId } = useParams(); // Get unitId from the URL
  const navigate = useNavigate();

  const handleVideoChange = (event) => {
    const files = Array.from(event.target.files);
    setVideos(files);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    if (videos.length === 0) {
      console.error("No videos to upload");
      return;
    }

    videos.forEach((video) => {
      formData.append("files", video);
    });

    setIsLoading(true);

    try {
      const response = await axiosInstance.post(
        "/units/video-inspection/" + unitId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            console.log("Upload progress:", percentCompleted);
          },
        }
      );

      setVideos([]);
      setUploadProgress(0);
      setUploadError(null);
      setIsLoading(false);

      alert("Files uploaded successfully");
      navigate("/dashboard/units");
      return response;
    } catch (err) {
      console.error("Client - Error uploading files:", err);
      setUploadError(
        "Error uploading files. Please check your network and try again."
      );
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p className="text-gray-500">Record video inspection for the unit</p>
      <input
        type="file"
        accept="video/*"
        capture="environment"
        onChange={handleVideoChange}
        className="hidden"
        id="video-record"
        disabled={isLoading}
      />
      <label
        htmlFor="video-record"
        className={`flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 cursor-pointer ${
          isLoading ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        <FaVideo className="mr-2" />
        Record video
      </label>

      {videos.length > 0 && (
        <div className="flex flex-wrap justify-center items-center space-x-4">
          {videos.map((video, index) => (
            <div key={index} className="relative video-item m-">
              <video
                src={URL.createObjectURL(video)}
                controls
                className="w-24 h-24 object-cover rounded-md"
              />
              <div className="absolute top-0 right-0 flex space-x-1 mt-1 mr-1">
                <button
                  className="p-1 bg-red-500 text-white rounded-full shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
                  onClick={() =>
                    setVideos(videos.filter((_, i) => i !== index))
                  }
                  disabled={isLoading}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {isLoading && (
        <div className="flex flex-col items-center mt-4">
          <div className="loader"></div>
          <p className="text-blue-500 mt-2">
            Uploading... Please do not close this screen.
          </p>
        </div>
      )}

      {videos.length > 0 && (
        <button
          onClick={handleUpload}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          disabled={isLoading || videos.length === 0}
        >
          Upload Videos
        </button>
      )}

      {uploadError && <p className="text-red-500 mt-2">{uploadError}</p>}
    </div>
  );
};

export default VideoInspection;
