import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../AuthContext'
import Header from '../../../components/Header'
import PhotoInspection from './components/driverComponents/PhotoInspection'
import VideoInspection from './components/driverComponents/VideoInspection'
import UnitDetails from './components/ownerComponents/UnitDetails'
import { Button } from './../../../components/Button'

export default function Units() {
  const { user } = useAuth()
  const [userRole, setUserRole] = useState(null)
  const [selectedInspection, setSelectedInspection] = useState(null)

  let loadedData = false

  useEffect(() => {
    if (!user) {
      return
    }

    if (!loadedData) {
      loadedData = true

      setUserRole(user.currentCompanyRole.role.name)
    }
  }, [user])

  return (
    <div className="flex">
      <div className="flex-1">
        <Header />
        <div className="container mx-auto p-4">
          {userRole === 'owner' ? (
            <UnitDetails />
          ) : userRole === 'driver' ? (
            <div className="flex flex-col items-center p-4">
              <div className="flex space-x-4 mb-4">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                  onClick={() => setSelectedInspection('photo')}
                >
                  Photo Inspection
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                  onClick={() => setSelectedInspection('video')}
                >
                  Video Inspection
                </button>
              </div>
              <div className="w-full">
                {selectedInspection === 'photo' && <PhotoInspection />}
                {selectedInspection === 'video' && <VideoInspection />}
              </div>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-red-500">
                We don't recognize this unit. Please contact your administrator!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
