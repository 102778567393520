import React, { useEffect, useState } from "react";
import axiosInstance from "./../../../../../api/axiosInstance";

import PicturesPerDay from "./subComponents/PicturesPerDay";
import { FaClipboardCheck, FaCamera, FaEdit, FaTrash } from "react-icons/fa";

import ImageUploading from "react-images-uploading";
import { useParams, useNavigate } from "react-router-dom";

export default function PhotoInspection() {
  const [images, setImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const maxNumber = 69;
  const { unitId } = useParams(); // Get unitId from the URL
  const navigate = useNavigate(); // Initialize navigate function

  const onChange = (imageList) => {
    setImages(imageList);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    if (images.length === 0) {
      console.error("No images to upload");
      return;
    }

    images.forEach((image, index) => {
      formData.append("files", image.file);
    });

    try {
      const response = await axiosInstance.post(
        "/units/photo-inspection/" + unitId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            console.log("Upload progress:", percentCompleted);
          },
        }
      );

      setImages([]);
      setUploadProgress(0);
      setUploadError(null);

      alert("Files uploaded successfully");
      navigate("/dashboard/units");
      return response;
    } catch (err) {
      console.error("Client - Error uploading files:", err);
      setUploadError(
        "Error uploading files. Please check your network and try again."
      );
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-center items-center space-x-4">
        <FaClipboardCheck className="text-4xl text-green-500" />
        <div>
          <h1 className="text-2xl font-semibold">Photo Inspection</h1>
          <p className="text-gray-500">Upload photo inspection for the unit</p>
        </div>
      </div>

      {/* Showing a calendar with dates and in each date to show count of how many pictures are uploaded */}
      <PicturesPerDay />

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="flex flex-col justify-center items-center space-y-4 p-4">
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
              <button
                className="flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <FaCamera className="mr-2" />
                Add pictures
              </button>
              {images.length > 0 && (
                <button
                  className="flex items-center justify-center px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
                  onClick={onImageRemoveAll}
                >
                  Remove all images
                </button>
              )}
            </div>
            <div className="flex flex-wrap justify-center items-center space-x-4">
              {imageList.map((image, index) => (
                <div key={index} className="relative image-item m-2">
                  <img
                    src={image["data_url"]}
                    alt=""
                    className="w-24 h-24 object-cover rounded-md"
                  />
                  <div className="absolute top-0 right-0 flex space-x-1 mt-1 mr-1">
                    <button
                      className="p-1 bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                      onClick={() => onImageUpdate(index)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="p-1 bg-red-500 text-white rounded-full shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75"
                      onClick={() => onImageRemove(index)}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>

      {uploadProgress > 0 && (
        <div className="w-full bg-gray-200 rounded-full">
          <div
            className="bg-blue-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
            style={{ width: `${uploadProgress}%` }}
          >
            {uploadProgress}%
          </div>
        </div>
      )}
      {uploadError && (
        <div className="w-full">
          <span className="text-red-500">{uploadError}</span>
          <br />
          <button
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            onClick={handleUpload}
          >
            Retry
          </button>
        </div>
      )}
      {images.length > 0 && !uploadError && (
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          onClick={handleUpload}
        >
          Upload to Cloud
        </button>
      )}
    </div>
  );
}
