import axiosInstance from "./axiosInstance";

const getUnits = async (companyId) => {
  try {
    const response = await axiosInstance.get(`/units/${companyId}`);

    return response.data;
  } catch (error) {
    console.error("Failed to fetch units", error);
    throw error;
  }
};

const createUnit = async (unit) => {
  try {
    const response = await axiosInstance.post("/units", unit);
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const updateUnit = async (unit) => {
  try {
    const response = await axiosInstance.put(`/units/${unit._id}`, unit);
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const deleteUnit = async (unit) => {
  try {
    const response = await axiosInstance.delete(`/units/${unit._id}`);
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const getUnitInpections = async (unitId) => {
  try {
    const response = await axiosInstance.get(`/units/${unitId}/inspections`);
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

export { getUnits, createUnit, updateUnit, deleteUnit, getUnitInpections };
