import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthContext";
import Header from "../../../../components/Header";
import InspectionsByDate from "./components/InspectionsByDate";

export default function Units() {
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);
  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      setUserRole(user.currentCompanyRole.role.name);
    }
  }, [user]);

  return (
    <div className="flex ">
      <div className="flex-1">
        <Header />
        <div className="container mx-auto p-4">
          {userRole === "owner" ? (
            <InspectionsByDate />
          ) : userRole === "driver" ? (
            <span>driver unit inspection</span>
          ) : (
            <div className="text-center">
              <p className="text-red-500">
                We don't have the unit inspection. Please contact your
                administrator!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
