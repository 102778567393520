import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDriverPicturesPerDay } from "../../../../../../api/driverAPI";
import { useAuth } from "../../../../../../AuthContext";
import moment from "moment-timezone";

const PicturesPerDay = () => {
  const { unitId } = useParams();
  const { user } = useAuth();
  const [picturesPerDay, setPicturesPerDay] = useState([]);

  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!loadedData) {
      const fetchPicturesPerDay = async () => {
        const pictures = await getDriverPicturesPerDay(unitId);
        setPicturesPerDay(pictures);
      };

      fetchPicturesPerDay();
      loadedData = true;
    }
  }, [user]);

  const renderCalendar = () => {
    const now = moment().startOf("day"); // Adjusted for timezone
    const daysInMonth = now.daysInMonth();
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    return daysArray.map((day) => {
      const date = moment(now).date(day).startOf("day"); // Adjusted for timezone
      const dateString = date.format("YYYY-MM-DD");
      const pictureData = picturesPerDay.find((p) => p.date === dateString);
      const isToday = date.isSame(now, "day");

      return (
        <div
          key={day}
          className={`border p-1 sm:p-2 text-center rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 ${
            isToday ? "border-solid border-2 border-green-500" : ""
          }`}
        >
          <div className="text-xs sm:text-sm font-semibold ">{day}</div>
          {pictureData ? (
            <div className="text-green-500 mt-1 sm:mt-2 text-xs sm:text-sm">
              {pictureData.count} pictures
            </div>
          ) : (
            <div className="text-gray-500 mt-1 sm:mt-2 text-xs sm:text-sm">
              0 pictures
            </div>
          )}
        </div>
      );
    });
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const now = moment();
  const monthName = monthNames[now.month()];

  return (
    <div className="p-2 sm:p-4">
      <h2 className="text-md sm:text-lg font-semibold mb-4 sm:mb-6">
        {monthName} {now.year()}
      </h2>
      <div className="grid grid-cols-4 sm:grid-cols-7 gap-1 sm:gap-2">
        {renderCalendar()}
      </div>
    </div>
  );
};

export default PicturesPerDay;
