import React, { useEffect, useState } from "react";
import { useAuth } from "./../../../../../AuthContext";
import { getUnitInpections } from "./../../../../../api/unitAPI";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronDown, FaChevronUp, FaPlay } from "react-icons/fa";

const InspectionsByDate = () => {
  const { user } = useAuth();
  const { unitId } = useParams();
  const [inspections, setInspections] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const cloudflareUrl = `https://1f4a14fe2f67362c2d2affc164b8e9d6.r2.cloudflarestorage.com/drivepik`;
  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      const getInspections = async (unitId) => {
        const inspections = await getUnitInpections(unitId);
        console.log(inspections);
        setInspections(inspections);
      };

      getInspections(unitId);
      loadedData = true;
    }
  }, [user]);

  const toggleDropdown = (id) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const openVideoModal = (videoUrl) => {
    setSelectedVideo(videoUrl);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="space-y-2">
      {inspections.length === 0 ? (
        <p className="text-center text-gray-500">
          The unit wasn't inspected yet.
        </p>
      ) : (
        inspections.map((inspection) => (
          <div key={inspection._id} className="bg-white shadow-md p-4">
            <div className="flex justify-between items-center">
              <div>
                <p>
                  <span className="font-bold">Date:</span> {inspection._id}
                </p>
                <p>
                  <span className="font-bold">Files:</span> {inspection.count}
                </p>
              </div>
              <button
                onClick={() => toggleDropdown(inspection._id)}
                className="focus:outline-none"
              >
                {openDropdowns[inspection._id] ? (
                  <FaChevronUp className="text-xl" />
                ) : (
                  <FaChevronDown className="text-xl" />
                )}
              </button>
            </div>
            {openDropdowns[inspection._id] && (
              <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {inspection.files.map((file) => {
                  const fileUrl = `${cloudflareUrl}/${file.cloudflareKey}`;
                  return (
                    <div
                      key={file.cloudflareKey}
                      className="flex justify-center items-center"
                    >
                      {file.type === "photo" ? (
                        <img
                          src={file.signedUrl}
                          alt="Inspection"
                          className="w-full h-auto object-cover cursor-pointer"
                          loading="lazy"
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                          onClick={() => openImageModal(file.signedUrl)}
                          onError={(e) => {
                            console.error(
                              "Image failed to load:",
                              file.signedUrl
                            );
                            e.target.style.display = "none";
                          }}
                        />
                      ) : (
                        <div
                          className="relative cursor-pointer"
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                          onClick={() => openVideoModal(file.signedUrl)}
                        >
                          <FaPlay className="absolute inset-0 m-auto text-white text-4xl" />
                          <video
                            src={file.signedUrl}
                            className="w-full h-auto object-cover"
                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                            onError={(e) => {
                              console.error(
                                "Video failed to load:",
                                file.signedUrl
                              );
                              e.target.style.display = "none";
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))
      )}

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeImageModal}
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={closeImageModal}
              className="absolute top-0 right-0 m-4 text-white text-2xl"
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Full Inspection"
              className="max-w-full max-h-full"
            />
          </div>
        </div>
      )}

      {selectedVideo && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeVideoModal}
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={closeVideoModal}
              className="absolute top-0 right-0 m-4 text-white text-2xl"
            >
              &times;
            </button>
            <video
              src={selectedVideo}
              controls
              autoPlay
              className="max-w-full max-h-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InspectionsByDate;
